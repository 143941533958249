<script>
  import MyPosition from './svgs/MyPosition.svelte'
  import Condo from './svgs/Condo.svelte'
  import {
    resetStoresState,
    showCityList,
    hideCityList,
    doPositionSearch,
    setOfflineStoreMode,
    resetZipCode
  } from '../actions'
  import {
    cityListViewStore as cityListShown,
    selectedCityStore as selectedCity,
    selectedZipCodeStore as selectedZipCode,
    listModeStore as listMode
  } from '../stores'
  import { trackClick } from '../utils'
  import { trackEvent } from '../utils/track'

  const showCityListView = () => {
    trackEvent({event: 
      'GenericStoreSearchCity'})
    resetZipCode()
    resetStoresState()
    setOfflineStoreMode(false)
    showCityList()
  }

  const searchOnZipAgain = () => {
    trackClick({ action: 'Sök med postnummer' })
    resetZipCode()
    setOfflineStoreMode(false)
    resetStoresState()
    hideCityList()
  }
</script>

<style lang="scss">button {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 400; }
  @media (hover: hover) {
    button:hover {
      text-decoration: underline; } }

ul {
  margin: calc(12px * 2) 0;
  display: flex;
  justify-content: center; }

li:first-child {
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid #fff; }</style>

<ul class="ss-list">
  <li>
    {#if $selectedZipCode && $listMode}
      <button class="ss-button" on:click={searchOnZipAgain}>
        <Condo />
        Byt postnummer
      </button>
    {:else if $selectedCity}
      <button class="ss-button" on:click={searchOnZipAgain}>
        <Condo />
        Sök på postnummer
      </button>
    {:else}
      <button class="ss-button" on:click={doPositionSearch}>
        <MyPosition />
        Butiker nära dig
      </button>
    {/if}
  </li>
  <li>
    {#if $cityListShown}
      <button class="ss-button" on:click={searchOnZipAgain}>
        <Condo />
        Sök på postnummer
      </button>
    {:else}
      <button
        data-automation-id="store-selector-stores-by-city"
        class="ss-button"
        on:click={showCityListView}>
        <Condo />
        {#if $selectedCity}Byt ort{:else}Hitta butik efter ort{/if}
      </button>
    {/if}
  </li>
</ul>
