<script>
  import { loadingStore as loading } from '../stores'
  export let position = 'standalone'
</script>

<style lang="scss">.store-selector__loading {
  display: block;
  opacity: 0;
  width: 30px;
  height: 30px;
  font-size: 10px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #eb1f07;
  transform: translateZ(0);
  -webkit-animation: loading 1s linear infinite;
          animation: loading 1s linear infinite;
  transition: opacity 0.2s ease-in-out;
  box-sizing: border-box; }
  .store-selector__loading--visible {
    opacity: 1; }
  .store-selector__loading--pos-right {
    position: absolute;
    top: 50%;
    right: calc(12px * 2);
    margin-top: -15px; }
  .store-selector__loading--pos-center {
    margin: 0 auto; }

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(1turn); } }

@keyframes loading {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(1turn); } }</style>

<span
  class:store-selector__loading--visible={$loading}
  class:store-selector__loading--pos-center={position === 'center'}
  class:store-selector__loading--pos-right={position === 'right'}
  class="store-selector__loading" />
