<style>
  svg {
    height: 20px;
  }</style>

<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 130 54.4">
  <g>
    <path
      fill="#E82625"
      d="M92.7,32L75,0L63.9,20c-0.2,0.4-1,1.5-1.6,2.1c-1.5,1.5-3.5,2.6-6,2.6c-4.6,0-8.2-3.7-8.2-8.2
      s3.7-8.2,8.2-8.2c1.6,0,3.1,0.5,4.3,1.2l3.8-6.9c-2.4-1.4-5.1-2.2-8.2-2.2c-8.9,0-16.1,7.2-16.1,16.1c0,8.9,7.2,16.1,16.1,16.1
      c5.3,0,10.1-2.6,13-6.6c0.6-0.8,1.2-1.8,1.8-2.9l3.9-6.9l3.7,6.7H76l-3.1,5.6h8.9l2,3.5C83.7,32,92.7,32,92.7,32z" />
    <rect x="29.3" y="0.9" fill="#E82625" width="7.9" height="31.2" />
    <path
      fill="#E82625"
      d="M9.7,37.1c1.1,0,2.5,0.5,3.4,1.3l2.5-4.7c-1.6-1-3.6-1.6-5.4-1.6c-5.3,0-8.4,2.5-8.4,6.7
      c0,2.4,1.9,4.2,4,4.6l1.2,0.2c0.9,0.2,1.2,0.4,1.2,1c0,0.6-0.7,0.8-1.8,0.8c-1.4,0-2.6-0.5-3.6-1.4l-0.3-0.3L0,48.5
      c1.8,1.2,4.1,2,6.6,2c4.7,0,8.1-2.2,8.1-6.8c0-2.5-1.6-4.4-4.1-4.8l-1.3-0.2c-0.8-0.1-1-0.3-1-0.8C8.4,37.4,8.8,37.1,9.7,37.1z" />
    <path
      fill="#E82625"
      d="M24.4,39.4l-1,5.5c-0.1,0.8-0.6,1.2-1.4,1.2c-0.9,0-1-0.6-0.9-1.2l0.9-5.5h-4.9l-1,6c-0.5,3.4,2.2,5,5.3,5
      c3.6,0,6.2-1.3,6.9-5l1-6H24.4z" />
    <path
      fill="#E82625"
      d="M38.8,39.1c-1.2,0-2.6,0.5-3.5,1.6h0l0.2-1.2h-4.1l-2.4,14.9h4.1l0.8-5.3c0.7,0.9,1.9,1.4,3.1,1.4
      c3.2,0,5.8-2.6,6.2-5.7C43.7,41.8,41.9,39.1,38.8,39.1z
      M39.2,44.8c-0.2,1.3-1.2,2.3-2.7,2.3c-1.5,0-2.2-1-2-2.3
      c0.2-1.3,1.2-2.4,2.7-2.4S39.4,43.5,39.2,44.8z" />
    <path
      fill="#E82625"
      d="M51.3,39.1c-3.5,0-6.2,2.1-6.8,5.7c-0.6,3.7,1.7,5.7,5.3,5.7c2.4,0,5-1.1,6-3.6h-3.4
      c-0.5,0.7-1.3,0.9-2.1,0.9c-1.6,0-2.3-0.8-2.1-2.4h8.1l0.1-0.4C56.8,41.4,55,39.1,51.3,39.1z
      M48.3,43.5c0.4-1.3,1.5-1.9,2.8-1.9 c1.1,0,2,0.8,2.1,1.9H48.3z" />
    <path
      fill="#E82625"
      d="M64.3,39.2c-1.1,0-2.3,0.5-2.9,1.6h0l0.2-1.3h-3.1l-1.7,10.6h3.1l0.8-4.7c0.3-1.6,0.8-3.3,2.8-3.3
      c0.4,0,0.6,0,0.9,0.2l1.2-2.8C65.1,39.3,64.7,39.2,64.3,39.2z" />
    <path
      fill="#E82625"
      d="M78.6,39.1c-1.2,0-2.5,0.6-3.4,1.7c-0.5-1.1-1.5-1.7-2.8-1.7c-1,0-2.3,0.5-2.9,1.5h-0.1l0.2-1.1h-3
      l-1.7,10.6h3l0.8-5.2c0.2-1.3,0.6-3.3,2.2-3.2c1.6,0,1.1,2,0.9,3l-0.9,5.4h3l0.9-5.2c0.2-1.2,0.5-3.3,2.1-3.2
      c1.4,0.1,1.2,1.8,1,2.9l-0.9,5.6h3l1.1-6.6C81.7,41.1,81.1,39.1,78.6,39.1z" />
    <path
      fill="#E82625"
      d="M91.9,41.3L91.9,41.3c-0.7-1.3-2-2.1-3.5-2.1c-3.3,0-5.3,2.6-5.8,5.5c-0.5,3,0.9,5.7,4.2,5.7
      c1.7,0,3-0.7,3.9-2h0l-0.3,1.7h2.6l1.7-10.6h-2.6L91.9,41.3z
      M90.9,44.9c-0.3,1.7-1.5,3.1-3.3,3.1c-1.7,0-2.5-1.5-2.2-3.1
      c0.3-1.6,1.5-3,3.2-3C90.3,41.9,91.1,43.2,90.9,44.9z" />
    <path
      fill="#E82625"
      d="M102.2,39.2c-1.4,0-2.5,0.5-3.2,1.8h-0.1l0.3-1.5h-2.3l-1.7,10.6h2.3l0.8-5.2c0.3-1.7,0.9-3.2,2.9-3.2
      c0.6,0,1.2,0.2,1.6,0.4l1-2.4C103.4,39.4,102.8,39.2,102.2,39.2z" />
    <polygon
      fill="#E82625"
      points="113.7,39.4 111,39.4 106.4,43.4 108.1,32.2 106.2,32.2 103.3,50.1
      105.3,50.1 105.9,46 107.2,44.9 110.6,50.1 113.1,50.1 108.8,43.5 " />
    <path
      fill="#E82625"
      d="M119.4,39.2c-3.4,0-5.6,2.4-6.1,5.7c-0.5,3.1,1.1,5.5,4.4,5.6c2.3,0,4.1-1.1,5.4-2.9l-1.5-0.9
      c-1,1.3-2,2.1-3.6,2.1c-2.2,0-3.3-1.3-2.8-3.5h8.2l0.1-0.4C124,41.6,122.8,39.2,119.4,39.2z
      M115.6,43.6c0.5-1.5,1.8-2.8,3.6-2.8 c2.2,0,2.7,1.1,2.7,2.8H115.6z" />
    <polygon
      fill="#E82625"
      points="128,39.4 128.6,35.3 127.1,35.3 126.4,39.4 125.2,39.4 125,40.9
      126.2,40.9 124.7,50.1 126.3,50.1 127.7,40.9 129.7,40.9 130,39.4 " />
  </g>
</svg>
