<style>
  svg {
    height: 20px;
  }</style>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 632 353">
  <path
    style="fill:#ed1c24"
    id="path9"
    d="m 133.54251,6.1427857 45.12536,0.2524434 0.004,178.3800409
    -45.12979,-0.004 c 0,0 -0.0133,-178.6679138 0,-178.6280543" />
  <path
    style="fill:#ed1c24"
    id="path11"
    d="m 200.73674,66.981641 c 6.59896,-19.265416 20.40362,-38.774418
    39.15087,-49.757919 19.602,-12.3785839 45.45309,-17.54260117
    69.37321,-11.4883888 8.97724,1.8822533 16.24495,4.5926978
    25.06718,10.1287368 0.44732,0.314447 0.42074,0.159438 0.71748,0.655467 l
    -22.13973,38.964858 c -9.69471,-5.482893 -21.67913,-8.29963
    -33.54397,-5.863772 -7.63088,1.213499 -14.28741,4.800853 -20.65164,9.114534
    -10.81521,9.389123 -16.98457,21.408971 -17.64004,35.346503 -0.18601,9.4467
    2.0417,18.13606 6.5591,25.77137 10.31032,16.83842 29.02656,25.47021
    48.47356,22.75091 13.56108,-1.80254 26.15668,-10.4609 33.29596,-22.60476 l
    4.88943,-8.63622 L 395.36616,-3.3136e-6 498.45606,184.76641 h -51.89261 c
    -3.6715,-6.61667 -11.32895,-19.7703 -11.32895,-19.7703 l -51.43202,0.009
    17.53817,-32.54748 15.68249,-0.11515 -21.40454,-38.154378 -5.09316,9.012668
    -17.43188,31.49342 c -9.39355,18.11392 -26.11238,36.30756 -44.93935,44.66034
    -19.92974,10.30146 -48.61971,11.56811 -70.17483,3.86194 -15.49648,-5.19945
    -30.64751,-15.13775 -41.00212,-28.84055 -16.19181,-20.53206
    -22.76419,-42.30863 -19.40271,-70.693006 0.54918,-4.628129 1.9664,-12.338724
    3.16219,-16.701123" />
  <path
    style="fill:#ed1c24"
    id="path13"
    d="m 631.99861,327.05147 c 0,14.2697 -11.6744,25.94853 -25.94409,25.94853 H
    25.95291 C 11.678787,353 -4.14534e-5,341.32117 -4.14534e-5,327.05147 v
    -85.48973 c 0,-14.27412 11.6832574534,-25.95295 25.9529514534,-25.95295 h
    580.09718 c 14.27412,0 25.9441,11.67883 25.9441,25.95295 v 85.48973" />
  <polyline
    style="fill:#ffffff"
    id="polyline15"
    points="421.58,130.984 425.984,130.984 425.984,137.732 426.031,137.732
    431.004,130.984 436.308,130.984 430.199,138.679 436.781,147.511
    431.169,147.511 426.031,140.005 425.984,140.005 425.984,147.511
    421.58,147.511 421.58,130.984 "
    transform="matrix(4.4288313,0,0,4.4288313,-1827.699,-335.38654)" />
  <polyline
    style="fill:#ffffff"
    id="polyline17"
    points="438.32,130.984 442.5,130.984 446.583,143.558 450.666,130.984
    454.846,130.984 449.306,147.511 443.86,147.511 438.32,130.984 "
    transform="matrix(4.4288313,0,0,4.4288313,-1827.699,-335.38654)" />
  <path
    style="fill:#ffffff"
    id="path19"
    d="m 182.22865,317.91479 h 19.81902 l 5.77077,-15.62491 h 27.19302 l
    5.73534,15.62491 h 21.39568 L 233.93083,244.7195 H 210.024 l
    -27.79535,73.19529 m 47.67637,-29.46501 h -16.94471 l 8.54322,-26.3604
    8.40149,26.3604 z" />
  <polyline
    style="fill:#ffffff"
    id="polyline21"
    points="474.262,130.984 478.322,130.984 485.438,140.5 485.438,130.984
    489.464,130.984 489.464,147.512 485.438,147.512 478.311,137.272
    478.311,147.512 474.262,147.512 474.262,130.984 "
    transform="matrix(4.4288313,0,0,4.4288313,-1827.699,-335.38654)" />
  <polyline
    style="fill:#ffffff"
    id="polyline23"
    points="496.992,134.251 492.92,134.251 492.92,130.984 505.469,130.984
    505.469,134.251 501.396,134.251 501.396,147.511 496.992,147.511
    496.992,134.251 "
    transform="matrix(4.4288313,0,0,4.4288313,-1827.699,-335.38654)" />
  <path
    style="fill:#ffffff"
    id="path25"
    d="m 490.63475,290.12831 c 0,20.02717 -13.00305,29.04427 -32.08689,29.04427
    -19.08383,0 -32.08688,-9.0171 -32.08688,-29.04427 V 244.7195 h 19.50458 v
    42.15804 c 0,9.646 2.62186,17.82605 12.5823,17.82605 9.96045,0
    12.58231,-8.18005 12.58231,-17.82605 V 244.7195 h 19.50458 v 45.40881" />
  <polyline
    style="fill:#ffffff"
    id="polyline27"
    points="527.205,130.984 533.308,130.984 536.859,140.5 540.381,130.984
    546.485,130.984 546.485,147.511 542.198,147.511 542.198,137.272
    538.675,147.511 535.044,147.511 531.514,137.272 531.514,147.511
    527.205,147.511 527.205,130.984"
    transform="matrix(4.4288313,0,0,4.4288313,-1827.699,-335.38654)" />
</svg>
