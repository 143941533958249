<script>
  import Loading from './Loading.svelte'
  import {
    selectedZipCodeStore as selectedZipCode
  } from '../stores'

  export let handleZipCodeChange
  let inputElement

  $: if (inputElement) {
    inputElement.focus()
  }
</script>

<style lang="scss">input {
  line-height: 50px;
  width: calc(100% - 30px);
  background-color: transparent;
  border: 0;
  display: block;
  border-radius: 50px;
  outline: 0;
  padding: 0 12px;
  font-family: ICATextNy, arial, serif;
  letter-spacing: 2px;
  font-size: 24px;
  letter-spacing: 3px;
  -moz-appearance: textfield;
  color: #3A3A3A; }

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

label {
  color: #3A3A3A;
  font-family: ICATextNy, arial, serif;
  line-height: 50px;
  font-size: 16px;
  white-space: nowrap; }
  @media only screen and (min-width: 600px) {
    label {
      font-size: 18px; } }

div {
  position: relative;
  display: flex;
  flex: 0 1 100%;
  margin: 0 12px;
  max-width: 550px;
  border-radius: 50px;
  background-color: #fff;
  padding: 0 calc(12px * 2); }
  @media only screen and (min-width: 600px) {
    div {
      margin: 0 auto; } }</style>

<div>
  <label for="zipcode">Ditt postnummer:</label>
  <input
    bind:this={inputElement}
    on:input={handleZipCodeChange}
    name="zipcode"
    id="zipcode"
    type="number"
    pattern="[0-9]*"
    maxlength="5"
    value={$selectedZipCode} />
  <Loading position="right" />
</div>
