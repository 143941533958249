<style>
  svg {
    height: 20px;
  }</style>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="28.5 0 73 50.1">
  <g>
    <path
      fill="#E82625"
      d="M64.2,40.9h2.8v1.6h0c0.6-1.3,1.9-1.9,3.3-1.9c2.4,0,3.4,1.7,3.4,3.6v5.6h-3.1v-4.3c0-1.7-0.6-2.3-1.4-2.3
      c-1.3,0-1.9,0.9-1.9,2.8v3.8h-3.1V40.9L64.2,40.9z" />
    <path
      fill="#E82625"
      d="M84,49.9c-0.1-0.7-0.2-1.4-0.2-2.1v-3.3c0-2.8-2-3.7-4.3-3.7c-1.3,0-2.5,0.2-3.6,0.6l0,2.1
      c0.9-0.5,1.9-0.7,2.9-0.7c1.1,0,2,0.3,2,1.6c-0.4-0.1-0.9-0.1-1.4-0.1c-1.6,0-4.6,0.3-4.6,3.1c0,2,1.6,2.8,3.4,2.8
      c1.3,0,2.1-0.5,2.8-1.6h0c0,0.5,0.1,0.9,0.1,1.4L84,49.9L84,49.9z
      M76.9,39.6h2.2v-2.2h-2.2V39.6L76.9,39.6z M80.2,39.6h2.2v-2.2
      h-2.2V39.6L80.2,39.6z
      M77.8,47c0-0.9,0.8-1.2,1.9-1.2c0.5,0,0.9,0,1.3,0.1c0,1.1-0.7,2.1-1.9,2.1C78.3,48,77.8,47.7,77.8,47
      L77.8,47z" />
    <path
      fill="#E82625"
      d="M91.8,43.4c-0.3-0.1-0.7-0.1-1-0.1c-1.4,0-2.3,1-2.3,2.8v3.8h-3.1v-9h2.8v1.6h0c0.5-1.1,1.3-1.9,2.6-1.9
      c0.4,0,0.7,0.1,1,0.1L91.8,43.4L91.8,43.4z" />
    <path
      fill="#E82625"
      d="M101.2,49.9c-0.1-0.7-0.2-1.4-0.2-2.1v-3.3c0-2.8-2-3.7-4.3-3.7c-1.3,0-2.5,0.2-3.6,0.6l0,2.1
      c0.9-0.5,1.9-0.7,2.9-0.7c1.1,0,2,0.3,2,1.6c-0.4-0.1-0.9-0.1-1.4-0.1c-1.6,0-4.6,0.3-4.6,3.1c0,2,1.6,2.8,3.4,2.8
      c1.3,0,2.1-0.5,2.8-1.6h0c0,0.5,0.1,0.9,0.1,1.4L101.2,49.9L101.2,49.9z
      M94.9,47c0-0.9,0.8-1.2,1.9-1.2c0.5,0,0.9,0,1.3,0.1
      c0,1.1-0.7,2.1-1.9,2.1C95.5,48,94.9,47.7,94.9,47L94.9,47z" />
    <polygon fill="#E82625" points="28.8,40.9 61.4,40.9 61.4,49.9 28.8,49.9" />
    <path
      fill="#E82625"
      d="M80.8,0L101,36.4H90.8l-2.2-4H78.4l3.6-6.4H85l-4.2-7.6l-4.4,7.9c-0.7,1.2-1.4,2.3-2.1,3.3
      c-3.4,4.5-8.8,7.5-14.8,7.5c-10.1,0-18.4-8.2-18.4-18.4c0-10.1,8.2-18.4,18.4-18.4c3.5,0,6.6,0.9,9.3,2.5l-4.3,7.9
      c-1.4-0.8-3.1-1.4-4.9-1.4c-5.2,0-9.4,4.2-9.4,9.4s4.2,9.4,9.4,9.4c2.8,0,5.1-1.2,6.8-2.9c0.7-0.7,1.6-2,1.8-2.4L80.8,0L80.8,0z
      M28.8,1h9v35.5h-9V1L28.8,1z" />
  </g>
</svg>
