<style lang="scss">.store-selector__header {
  background-color: #fff; }

ul {
  display: flex; }

h2 {
  font-family: ICARubrik, arial, serif;
  font-weight: 300;
  font-size: 30px;
  margin: calc(12px * 2) 0;
  padding: 0 12px;
  color: #eb1f07;
  text-align: center; }
  @media only screen and (min-width: 600px) {
    h2 {
      font-size: 40px; } }

header {
  overflow: hidden; }

.store-selector__header__products {
  height: 110px;
  overflow: hidden;
  margin: 0 -40px -40px; }
  @media only screen and (min-width: 600px) {
    .store-selector__header__products {
      margin: 0; } }</style>

<header class="store-selector__header">
  <h2>
    Handla mat online
  </h2>
  <ul class="ss-list store-selector__header__products">
    <li>
      <img
        alt="Yoggi"
        src="//handla.ica.se/app/store/0.1.51629/images/product-1-a7e595a7dd28700401181a98593edbbc.png" />
    </li>
    <li>
      <img
        alt="Kycklingfilé"
        src="//handla.ica.se/app/store/0.1.51629/images/product-2-3998b38ea8bf2bb4da3f2a1e365cac22.png" />
    </li>
    <li>
      <img
        alt="Loka Crush Hallon"
        src="//handla.ica.se/app/store/0.1.51629/images/product-3-c208a546c9dfed8e7f8e60f3f62bea3e.png" />
    </li>
    <li>
      <img
        alt="Pulled Pork"
        src="//handla.ica.se/app/store/0.1.51629/images/product-4-a697477afb1a1321b29478124ee34885.png" />
    </li>
    <li>
      <img
        alt="Taco Sauce Organic"
        src="//handla.ica.se/app/store/0.1.51629/images/product-5-a423ac150c8e3d6f543385c9a49173b5.png" />
    </li>
  </ul>
</header>
