<script>

</script>

<style lang="scss">div {
  padding: calc(12px * 2);
  background-color: #f9f5f5;
  padding-bottom: calc(12px * 2);
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  position: relative; }</style>

<div>
  <slot />
</div>
