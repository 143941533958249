<style lang="scss">svg {
  fill: #cf2e05;
  height: 24px;
  vertical-align: text-bottom;
  display: inline-block; }</style>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path
    d="M21 24a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2m-9.95 0a1.001 1.001 0
    0 1 0-2 1.001 1.001 0 0 1 0 2m15.948-9.018l-1.027-5.403A3.001 3.001 0 0 0 23
    7H8a3 3 0 0 0-3 3v2H3.25a.25.25 0 0 0-.25.25v.5c0
    .138.112.25.25.25H5v1h-.75a.25.25 0 0 0-.25.25v.5c0
    .138.112.25.25.25H5v8.75c0 .138.112.25.25.25h1.5a.25.25 0 0 0
    .25-.25V15h6.75a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0
    0-.25-.25H7v-1h5.75a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25H7v-2a1 1 0
    0 1 1-1h15.004a1 1 0 0 1 .989.858l1.004 5.196.003.026V22h-1.185A2.995 2.995
    0 0 0 21 20a2.995 2.995 0 0 0-2.815 2h-4.319a2.997 2.997 0 0
    0-2.816-2c-1.654 0-3 1.346-3 3s1.346 3 3 3a2.997 2.997 0 0 0
    2.816-2h4.319A2.995 2.995 0 0 0 21 26a2.995 2.995 0 0 0 2.815-2h2.935a.25.25
    0 0 0 .25-.25v-8.742a.211.211 0 0 1-.002-.026M19 11h3.18l.6 3H19v-3zm4.695
    4a.25.25 0 0 0 .245-.299l-.9-4.5a.25.25 0 0 0-.245-.201H18.25a.25.25 0 0
    0-.25.25v4.5c0 .138.112.25.25.25h5.445z" />
</svg>
