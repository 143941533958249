<script>
  import SearchField from './SearchField.svelte'
  import {
    doZipCodeSearch,
    setSelectedZipCode,
    resetCityStoreCount,
    resetSelectedCity
  } from '../actions'
  import {
    noResultsStore as noResultAtAll,
    storesForHomeDeliveryStore as storesForHomeDelivery
  } from '../stores'
  import { trackClick } from '../utils'

  let noResults = false

  const handleZipCodeChange = e => {
    const zipNumber = e.target.value
    const zip = zipNumber.toString()

    if (zip.length > 5) {
      setSelectedZipCode(Number(zip.slice(0, 5)))
    }

    if (zip.length === 5) {
      doZipCodeSearch({ zip }).then(() => {
        trackClick({ action: 'Levererar vi till dig', label: zip })
        resetCityStoreCount()
        setTimeout(() => setSelectedZipCode(zipNumber), 0)
        if ($noResultAtAll || !$storesForHomeDelivery.length) {
          noResults = true
        } else {
          resetSelectedCity()
        }
      })
    } else {
      resetStoresState()
    }
  }
</script>

<style lang="scss">h4 {
  margin: 0;
  font-size: 26px;
  font-family: ICAHand, arial, sans-serif;
  margin: 0 auto; }

p {
  text-align: center;
  font-family: ICATextNy, arial, sans-serif;
  line-height: 20px; }</style>

<h4>Levererar vi till dig?</h4>
<p>Ange ditt postnummer för att se om vi levererar till ditt område.</p>
<SearchField {handleZipCodeChange} />
{#if noResults}
  {#if $noResultAtAll}
    <p>
      Inga butiker hittades. Försök igen eller klicka på "Hitta butik efter ort"
    </p>
  {:else if !$storesForHomeDelivery.length}
    <p>
      Det finns tyvärr inga butiker som levererar till ditt område, men det
      finns butiker där du enkelt kan hämta dina varor färdigpackade och klara.
      Klicka på
      <strong>'Hämta i butik'</strong>
      för att se vilka.
    </p>
  {/if}
{/if}
