<script>
  import { onMount } from 'svelte'
  import CloseButton from './CloseButton.svelte'
  import MenuBar from './MenuBar.svelte'
  import Header from './Header.svelte'
  import ZipcodeField from './ZipcodeField.svelte'
  import Menu from './Menu.svelte'
  import StoresList from './StoresList.svelte'
  import CityListView from './CityList.svelte'
  import SearchResultText from './SearchResultText.svelte'
  import OfflineStores from './OfflineStores.svelte'
  import AlternativeListing from './AlternativeListing.svelte'

  import {
    backdropShownStore as backDrop,
    cityListViewStore as cityListShown,
    selectedCityStore as selectedCity,
    selectedZipCodeStore as selectedZipCode,
    listModeStore as listMode,
    offlineStoreModeStore as offlineStoreMode,
    b2cOnlyStore as b2cOnly
  } from '../stores'

  onMount(() => {
    backDrop.set(true)
  })
</script>

<style lang="scss">.store-selector {
  max-width: 1000px;
  max-width: 100%;
  background-color: #eb1f07;
  position: absolute;
  top: 12px;
  left: calc(12px / 2);
  right: calc(12px / 2);
  margin-bottom: 12px;
  border-radius: 8px;
  z-index: 11; }
  @media only screen and (min-width: 900px) {
    .store-selector {
      top: calc(12px * 2);
      left: 50%;
      transform: translateX(-50%);
      width: 1000px; } }

:global(.ss-list) {
  list-style: none;
  margin: 0;
  padding: 0; }

:global(.ss-button) {
  border: 0;
  font-family: ICATextNy, arial, serif;
  cursor: pointer;
  background-color: transparent; }
  :global(.ss-button):focus {
    outline: solid 3px #10408b; }

:global(.ss-paragraph) {
  margin: 0;
  font-size: 14px;
  color: #3F3F40;
  font-family: ICATextNy, arial, sans-serif; }</style>

<div class="store-selector">
  {#if !$b2cOnly}
    <CloseButton />
    <MenuBar />
  {/if}
  {#if $listMode && ($selectedCity || $selectedZipCode)}
    <SearchResultText />
    <Menu />
    {#if $offlineStoreMode}
      <AlternativeListing>
        <OfflineStores />
      </AlternativeListing>
    {:else}
      <StoresList />
    {/if}
  {:else if $cityListShown}
    <CityListView />
  {:else}
    <Header />
    <ZipcodeField />
    <StoresList />
    <Menu />
  {/if}
</div>
