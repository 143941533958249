<script>
  import { onMount } from 'svelte'
  import Menu from './Menu.svelte'
  import Loading from './Loading.svelte'
  import StoresList from './StoresList.svelte'
  import SearchResultText from './SearchResultText.svelte'
  import {
    citiesStore as cities,
    cityListViewStore as cityListShown,
    listModeStore as listMode,
    selectedCityStore as selectedCity
  } from '../stores'
  import { getCitiesList, setStoresOfCity } from '../actions'
  import { trackClick } from '../utils'

  let selectedSuburb

  onMount(() => {
    getCitiesList()
  })

  const scrollToLetter = letter => {
    trackClick({ action: `Skrolla till bokstaven ${letter}` })
    const target = document.getElementById(`area_${letter}`)
    const targetOffsetToTop = target.getBoundingClientRect().top
    window.scrollTo({
      top: targetOffsetToTop - 5,
      left: 0,
      behavior: 'smooth'
    })
  }

  const onCityClick = city => {
    trackClick({ action: 'Välj stad', label: city.name })
    setStoresOfCity(city)
    window.scrollTo(0, 0)
  }
</script>

<style>
  h4 {
    font-family: ICATextNy, arial, serif;
    font-weight: 600;
    font-size: 30px;
    color: #3A3A3A;
    margin: 0;
  }
  header {
    background-color: #eb1f07;
    overflow: hidden;
  }
  strong {
    font-weight: 500;
  }
  button {
    background: transparent;
    border: 0;
    outline: 0;
    font-size: 20px;
    font-weight: 600;
    padding: calc(12px / 2)
  }
  button:disabled {
      opacity: 0.5;
      cursor: default;
    }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
  }
  a {
    font-size: 14px;
    font-family: ICATextNy, arial, sans-serif;
    color: #e13205;
    margin: 12px 0;
    display: inline-block;
  }
  select {
    display: block;
    font-size: 16px;
    font-family: ICATextNy, arial, sans-serif;
    font-weight: 600;
    color: #3A3A3A;
    line-height: 1.3;
    cursor: pointer;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    border: 1px solid #c0c0c0;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%233A3A3A%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%
  }
  select::-ms-expand {
      display: none;
    }
  select:focus {
      border-color: #e13205;
      box-shadow: 0 0 1px 1px #e13205;
      box-shadow: 0 0 0 1px -moz-mac-focusring;
      color: #222;
      outline: none;
    }
  select option {
      font-weight: normal;
    }
  .store-selector-areas {
    background-color: #f9f5f5;
    border-radius: 0 0 8px 8px;
    padding-bottom: 12px;
  }
  .store-selector-areas__alphabet {
    justify-content: center
  }
  .store-selector-areas__alphabet li {
      margin: 0 calc(12px / 2);
      width: 28px;
      text-align: center
    }
  @media only screen and (min-width: 600px) {
  .store-selector-areas__alphabet li {
        padding: 12px 0;
        width: auto
    }

        .store-selector-areas__alphabet li button {
          padding: 0 2px;
        }
      }
  .store-selector-areas__alphabet-list {
    padding: 0 calc(12px * 2)
  }
  .store-selector-areas__alphabet-list li {
      width: 100%;
      display: block;
    }
  @media only screen and (min-width: 600px) {
  .store-selector-areas__alphabet-list {
      padding: calc(12px * 2) calc(12px * 7)
  }
    }
  .store-selector-areas__city-list {
    padding: 12px 12px calc(12px * 2)
      12px
  }
  .store-selector-areas__city-list li {
      width: 50%;
      text-align: center;
      margin: calc(12px / 3) 0;
    }
  .store-selector-areas__city-list button {
      font-size: 16px;
      font-weight: 400;
      color: #e13205;
    }
  .store-selector-areas__cant-find-store {
    background-color: #f9f5f5;
    padding: 12px 12px 0 12px;
    text-align: center
  }
  .store-selector-areas__cant-find-store p {
      font-size: 14px;
    }</style>

<div class="store-selector-areas">
  <header>
    <SearchResultText />
    <Menu />
  </header>
  <ul class="ss-list store-selector-areas__alphabet">
    {#each $cities as area}
      <li>
        <button
          class="ss-button"
          on:click={() => scrollToLetter(area.letter)}
          disabled={!area.cities}>
          {area.letter}
        </button>
      </li>
    {/each}
  </ul>
  <Loading position="center" />
  <ul class="ss-list store-selector-areas__alphabet-list">
    {#each $cities as area}
      {#if area.cities}
        <li>
          <h4 id={`area_${area.letter}`}>{area.letter}</h4>
          <ul class="ss-list store-selector-areas__city-list">
            {#each area.cities as city}
              <li>
                {#if city.showSuburbs}
                  <select
                    bind:value={selectedSuburb}
                    on:change={() => onCityClick(selectedSuburb)}>
                    <option>{city.name}</option>
                    {#each city.pickupStores as suburb}
                      <option value={suburb}>{suburb.name}</option>
                    {/each}
                  </select>
                {:else}
                  <button class="ss-button" on:click={() => onCityClick(city)}>
                    {city.name}
                  </button>
                {/if}
              </li>
            {/each}
          </ul>
        </li>
      {/if}
    {/each}
  </ul>
  {#if $cities.length}
    <div class="store-selector-areas__cant-find-store">
      <p class="ss-paragraph">
        Hittar du inte din favoritbutik? Nedan hittar du en lista med samtliga
        ICA-butiker och vilka tjänster de erbjuder.
      </p>
      <a href="https://www.ica.se/butiker">Sök bland alla ICA-butiker</a>
    </div>
  {/if}
</div>
