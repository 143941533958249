<script>
  let close = () => {
    try {
      window.icaOnline.storeSelector.destroy()
    } catch (error) {
      console.error(error)
    }
  }
</script>

<style lang="scss">button {
  background: #fcece7;
  color: #cf2e05;
  position: absolute;
  top: 6px;
  right: 6px;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  font-size: 12px;
  transition: all .1s ease-in-out; }
  button:hover {
    background: #f088b6;
    color: #fff; }</style>

<button on:click={close}>✕</button>
