<style>
  svg {
    height: 20px;
  }</style>

<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="11.2 0 107.6 44.4">
  <g>
    <rect x="108.6" y="6.6" fill="#E82625" width="9.6" height="26.3" />
    <polygon
      fill="#E82625"
      points="11.8,0 26,0 30.1,17.9 34.3,0 48.2,0 48.2,33 38.6,33 38.5,12.5
      34,33 26.4,33 21.4,12.3 21.4,33 11.8,33 " />
    <g>
      <path
        fill="#5A6CB3"
        d="M62.6,24.1L62.6,24.1C62.5,24.1,62.3,24.5,62.6,24.1z" />
      <path fill="#E82625" d="M71,32.9L71,32.9C71,32.9,71.1,33,71,32.9z" />
      <path
        fill="#E82625"
        d="M104.9,32.9l-7.4-13.4l7.1-13h-9.2C92.3,12,93,10.7,93,10.7c-1.4-2.2-1.1-1.8-2.5-4.1h-9.6l7.1,13
        l-7.1,13.4h9.8c1.8-3,1.4-2.4,2.5-4.2c2.1,4.2,2.3,4.8,2.1,4.2H104.9z" />
      <path
        fill="#E82625"
        d="M72.1,6.6H58.7l-9,26.3h9.9c0.1-0.6,0,0,1.3-3.8l9,0c1.1,3.1,1.2,3.7,1.3,3.7h9.8L72.1,6.6z
        M62.5,24.1
        L62.5,24.1c0.3-0.5,1.1-2.3,3-7.5c0,0,3,8.9,2.5,7.5L62.5,24.1C62.3,24.5,62.5,24.1,62.5,24.1z" />
    </g>
    <rect x="11.8" y="35.4" fill="#E82625" width="2" height="8.7" />
    <path
      fill="#E82625"
      d="M24.6,39.8l1,1.9h-0.9l-0.8,1.5h2.5l0.5,1h2.5l-4.7-8.8l-3.3,5.5c-1.6,2.5-3.9,0.9-4.2-0.4
      c-0.5-1.9,1.5-3.9,3.4-2.7l1.1-1.9c-3-1.6-6.8,0.6-6.8,3.8c0,3.1,2.5,4.8,4.6,4.7c2.7,0,4-2.7,4-2.7L24.6,39.8z" />
    <path
      fill="#1D1D1B"
      d="M33.6,41.3c0,1.2,0.9,1.6,1.9,1.6c0.7,0,1.7-0.2,1.7-1.1c0-0.9-1.3-1.1-2.5-1.4c-1.3-0.3-2.5-0.8-2.5-2.5
      c0-1.8,1.6-2.6,3.2-2.6c1.8,0,3.4,0.8,3.4,2.8h-1.8c-0.1-1-0.8-1.3-1.7-1.3c-0.6,0-1.3,0.3-1.3,1c0,0.7,0.4,0.8,2.5,1.3
      c0.6,0.2,2.5,0.6,2.5,2.5c0,1.6-1.2,2.8-3.5,2.8c-1.9,0-3.7-1-3.6-3.1L33.6,41.3L33.6,41.3z" />
    <polygon
      fill="#1D1D1B"
      points="41.2,37 38.7,37 38.7,35.4 45.6,35.4 45.6,37 43.1,37 43.1,44.1
      41.2,44.1 " />
    <path
      fill="#1D1D1B"
      d="M49.1,35.2c2.6,0,4.2,2,4.2,4.6c0,2.5-1.6,4.5-4.2,4.5c-2.6,0-4.2-2-4.2-4.5
      C44.9,37.2,46.5,35.2,49.1,35.2z
      M49.1,42.7c1.7,0,2.3-1.5,2.3-2.9c0-1.5-0.6-3-2.3-3c-1.7,0-2.3,1.5-2.3,3
      C46.8,41.3,47.4,42.7,49.1,42.7z" />
    <path
      fill="#1D1D1B"
      d="M54,35.4h4.6c1.5,0,2.5,1.1,2.5,2.4c0,1-0.4,1.8-1.3,2.2v0c0.9,0.2,1.2,1.2,1.2,2c0,0.5,0,1.6,0.4,2.1
      h-1.9c-0.2-0.5-0.2-1.4-0.3-2.1c-0.1-0.9-0.5-1.3-1.4-1.3h-1.9v3.4H54L54,35.4L54,35.4z
      M55.9,39.4h2.1c0.8,0,1.3-0.4,1.3-1.2 c0-0.8-0.5-1.2-1.3-1.2h-2.1V39.4z" />
    <polygon
      fill="#1D1D1B"
      points="62,35.4 64.4,35.4 66.3,41.4 66.3,41.4 68.1,35.4 70.5,35.4
      70.5,44.1 68.9,44.1 68.9,38 68.9,38 66.9,44.1 65.6,44.1 63.6,38 63.6,38
      63.6,44.1 62,44.1 " />
    <path
      fill="#1D1D1B"
      d="M73.7,35.4h1.9l3.2,8.7h-1.9l-0.6-1.9H73l-0.7,1.9h-1.9L73.7,35.4z
      M73.5,40.8h2.2l-1.1-3.2h0L73.5,40.8z" />
    <path
      fill="#1D1D1B"
      d="M79,35.4h4.6c1.5,0,2.5,1.1,2.5,2.4c0,1-0.4,1.8-1.3,2.2v0c0.9,0.2,1.2,1.2,1.2,2c0,0.5,0,1.6,0.4,2.1
      h-1.9c-0.2-0.5-0.2-1.4-0.3-2.1c-0.1-0.9-0.5-1.3-1.4-1.3h-1.9v3.4H79C79,44.1,79,35.4,79,35.4z
      M80.8,39.4h2.1 c0.8,0,1.3-0.4,1.3-1.2c0-0.8-0.5-1.2-1.3-1.2h-2.1V39.4z" />
    <polygon
      fill="#1D1D1B"
      points="86.7,35.4 88.6,35.4 88.6,39.1 91.9,35.4 94.3,35.4 90.9,38.9
      94.6,44.1 92.2,44.1 89.7,40.2 88.6,41.3 88.6,44.1 86.7,44.1 " />
    <polygon
      fill="#1D1D1B"
      points="94.7,35.4 96.5,35.4 100,41.3 100.1,41.3 100.1,35.4 101.8,35.4
      101.8,44.1 99.9,44.1 96.5,38.3 96.4,38.3 96.4,44.1 94.7,44.1 " />
    <path
      fill="#1D1D1B"
      d="M105.4,35.4h1.9l3.2,8.7h-1.9l-0.6-1.9h-3.2l-0.7,1.9h-1.9L105.4,35.4z
      M105.2,40.8h2.2l-1.1-3.2h0 L105.2,40.8z" />
    <path
      fill="#1D1D1B"
      d="M110.6,35.4h3.6c2.2,0,3.8,1.4,3.8,4.3c0,2.5-1.2,4.4-3.8,4.4h-3.6V35.4z
      M112.5,42.5h1.6
      c1.1,0,2.1-0.7,2.1-2.6c0-1.8-0.6-2.9-2.4-2.9h-1.3L112.5,42.5L112.5,42.5z" />
  </g>
</svg>
