<style lang="scss">svg.condo {
  fill: #fff;
  height: 24px;
  vertical-align: text-bottom;
  display: inline-block; }</style>

<svg class="condo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path
    d="M14.75 4a.25.25 0 0 1 .25.25V5h8.75a.25.25 0 0 1 .25.25V26h3.75a.25.25 0
    0 1 .25.25v1.5a.25.25 0 0 1-.25.25H4.25a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0
    1 .25-.25H8V5.25A.25.25 0 0 1 8.25 5H13v-.75a.25.25 0 0 1 .25-.25zM22
    7H10v19h12V7zm-1.25 11a.25.25 0 0 1 .243.193l.007.057v2.5a.25.25 0 0
    1-.193.243L20.75 21h-1.5a.25.25 0 0 1-.243-.193L19 20.75v-2.5a.25.25 0 0 1
    .193-.243L19.25 18h1.5zm-5.5 0h1.5a.25.25 0 0 1 .243.193l.007.057v2.5a.25.25
    0 0 1-.193.243L16.75 21h-1.5a.25.25 0 0 1-.243-.193L15 20.75v-2.5a.25.25 0 0
    1 .193-.243L15.25 18h1.5zm-2.5-5a.25.25 0 0 1 .243.193l.007.057v2.5a.25.25 0
    0 1-.193.243L12.75 16h-1.5a.25.25 0 0 1-.243-.193L11 15.75v-2.5a.25.25 0 0 1
    .193-.243L11.25 13h1.5zm4 0a.25.25 0 0 1 .243.193l.007.057v2.5a.25.25 0 0
    1-.193.243L16.75 16h-1.5a.25.25 0 0 1-.243-.193L15 15.75v-2.5a.25.25 0 0 1
    .193-.243L15.25 13h1.5zm4 0a.25.25 0 0 1 .243.193l.007.057v2.5a.25.25 0 0
    1-.193.243L20.75 16h-1.5a.25.25 0 0 1-.243-.193L19 15.75v-2.5a.25.25 0 0 1
    .193-.243L19.25 13h1.5zm-8-5a.25.25 0 0 1 .243.193L13 8.25v2.5a.25.25 0 0
    1-.193.243L12.75 11h-1.5a.25.25 0 0 1-.243-.193L11 10.75v-2.5a.25.25 0 0 1
    .193-.243L11.25 8h1.5zm4 0a.25.25 0 0 1 .243.193L17 8.25v2.5a.25.25 0 0
    1-.193.243L16.75 11h-1.5a.25.25 0 0 1-.243-.193L15 10.75v-2.5a.25.25 0 0 1
    .193-.243L15.25 8h1.5zm4 0a.25.25 0 0 1 .243.193L21 8.25v2.5a.25.25 0 0
    1-.193.243L20.75 11h-1.5a.25.25 0 0 1-.243-.193L19 10.75v-2.5a.25.25 0 0 1
    .193-.243L19.25 8h1.5z" />
</svg>
