<style lang="scss">svg.myposition {
  fill: #fff;
  height: 24px;
  vertical-align: text-bottom;
  display: inline-block; }</style>

<svg class="myposition" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path
    d="M16 14a2 2 0 1 0 .001 4.001A2 2 0 0 0 16 14m0 9c-3.86
    0-7-3.141-7-7s3.14-7 7-7c3.859 0 7 3.141 7 7s-3.141 7-7
    7m11.75-8h-2.809A9.011 9.011 0 0 0 17 7.059V4.25a.25.25 0 0
    0-.25-.25h-1.5a.25.25 0 0 0-.25.25v2.809A9.012 9.012 0 0 0 7.059
    15H4.25a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h2.809A9.011 9.011 0 0 0
    15 24.941v2.809c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-2.809A9.01
    9.01 0 0 0 24.941 17h2.809a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.25-.25" />
</svg>
