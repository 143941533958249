<style lang="scss">svg.collect {
  fill: #cf2e05;
  height: 24px;
  vertical-align: text-bottom;
  display: inline-block; }</style>

<svg class="collect" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <path
    d="M26.884 11.517l-10.75-7.478a.25.25 0 0 0-.268 0l-10.75
    7.478-.116.211V26.75c0 .138.112.25.25.25h1.5a.25.25 0 0 0
    .25-.25V12.688l9-6.363 9 6.363V26.75c0 .138.113.25.25.25h1.5a.25.25 0 0 0
    .25-.25V11.728l-.116-.211M21 21.5V25h-9.875V15H21v6.5zM17.5
    12v1h-3v-1h3zm5.375 1H19.5v-1.31a.248.248 0 0
    0-.073-.177l-1.44-1.44a.248.248 0 0 0-.176-.073H14.19a.248.248 0 0
    0-.176.073l-1.44 1.44a.248.248 0 0 0-.073.176V13H9.25a.124.124 0 0
    0-.125.125V26.876c0 .068.055.124.124.124H22.75a.25.25 0 0 0
    .25-.25V13.125a.125.125 0 0 0-.125-.125z" />
</svg>
