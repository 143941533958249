<script>
  import SearchField from './SearchField.svelte'
  import { doZipCodeSearch, setSelectedZipCode, resetStoresState } from '../actions'

  import {
    noResultsStore as noResults
  } from '../stores'

  const handleZipCodeChange = e => {
    let zipNumber = e.target.value
    let zip = zipNumber.toString()

    if (!zip) {
      e.target.value = ""
    } else if (zip.length > 5) {
      zip = zip.slice(0, 5)
      zipNumber = Number(zip)
      e.target.value = zipNumber
    }

    if (zip.length === 5) {
      doZipCodeSearch({ zip }).then(() => setSelectedZipCode(zipNumber))
    } else {
      resetStoresState()
    }
  }
</script>

<style lang="scss">h3 {
  font-family: ICARubrik, arial, serif;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin: 12px; }
  @media only screen and (min-width: 600px) {
    h3 {
      font-size: 32px; } }

p {
  margin: 12px calc(12px * 2);
  font-style: italic;
  color: #fff;
  font-size: 15px;
  line-height: 1.5;
  font-family: ICATextNy, arial, sans-serif;
  text-align: center; }</style>

<h3>Ange postnummer och välj leveranssätt</h3>
<SearchField {handleZipCodeChange} />
{#if $noResults}
  <p>
    Inga butiker hittades. Försök igen eller klicka på "Hitta butik efter ort"
  </p>
{/if}
